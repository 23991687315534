li{
    @include f-14-primary-regular;
    padding: 10px 0 10px 20px;
    list-style: none;
    position: relative;

    &::before{
        content: "";
        left: 0;
        top: 15px;
        position: absolute;
        height: 10px;
        width: 10px;
        background: $bg-brand;
        border-radius: 50%;
    }
}

dl, ol, ul {
    padding-left: 0;
}