.o-sectionProducts{
    padding: 0;
    padding-bottom: 2rem;
    padding-top: 2rem;
    background: $bg-white;
}
@media screen and (max-width: 500px){
    .o-sectionProducts{
        padding: 0;
        padding-bottom: 2rem;
    }
}