section{
    padding: 6rem 0;
}
section h1{
    @include f-24-primary-semibold;
    font-weight: 700;
    text-align: center;
    margin-bottom: 3rem;
    padding-bottom: 1rem;
    position: relative;
    
    &::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;

        border: 3px $pink-light solid;
    }
}
@media screen and (max-width:575px){
    section{
        padding: 2rem 0;
    }
    section h1{
        @include f-18-primary-semibold;
        font-size: 20px;
        text-align: center;
        margin-bottom: 2rem;
    }
}