.m-productCarousel{
    position: relative;
}
.m-productCarousel__content{
    overflow: hidden;
    padding: 0px;
    background: transparent;
    border-radius: $border-radius-primary;
}
.m-productCarousel__wrap{

}
.m-productCarousel__slide{
    height: auto;
}
.m-productCarousel__swiperButton, .o-hero__swiperButton{
    background: rgba(0,0,0,0.5);
    border-radius: 0%;
    height: 40px;
    width: 40px;
    z-index: 3;

    &::after{
        content: "";
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjIwNyAxMiI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik05LjM0NSw5LjA1OWExLjIyNCwxLjIyNCwwLDAsMSwxLjcxNC0xLjcxNGw0LjksNC45YTEuMjI0LDEuMjI0LDAsMCwxLDAsMS43MTRsLTQuOSw0LjlhMS4yMjQsMS4yMjQsMCwwLDEtMS43MTQtMS43MTRMMTMuMzczLDEzLjEsOS4zMzMsOS4wNTlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOS4xIC03LjEpIi8+PC9zdmc+"); 
        height: 14px;
        width: 14px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    &.-prev{
        transform: rotate(180deg);
    }
    &.swiper-button-disabled{
        pointer-events: auto;
        opacity: 0;
    }
}

@media screen and (max-width: 500px){
    .m-productCarousel__content{
        padding: 0;
    }
}