.m-productDetailThumbs{
    padding-right: 20px;
}
.m-productDetailThumbs__content{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.m-productDetailThumbs__wrap{
}
.m-productDetailThumbs__slide{

}
.m-productDetailThumbs__slide.swiper-slide-active{
    border: 1px solid $border-medium;
    border-radius: $border-radius-primary;
}
.m-productDetailThumbs__paddingWrap{
    padding-bottom: 100%;
    position: relative;

}
.m-productDetailThumbs__figure{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
}
.m-productDetailThumbs__preloader{
    z-index: -1;
}
.m-productDetailThumbs__img{
    display: none;
    max-width: 100%;
    max-height: 100%;
}
.m-productDetailThumbs__img.swiper-lazy-loaded{
    display: block;
}
.m-productDetailThumbs__figcaption{
    display: none;
}   


