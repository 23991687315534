.o-productGrid{
    display: flex;
    flex-wrap: wrap;
}
.o-productGrid .m-productCell{
    width: 25%;
    padding: 0px;
}
.o-productGrid .m-productCell__link{
    border-right: 0; 
    border-bottom: 0;
}
.o-productGrid .m-productCell:nth-child(4n+4) .m-productCell__link{
    border-right: 1px solid $border-grey; 
}
@media screen and (max-width:991px){
    .o-productGrid{
        margin-left: -10px;
        margin-right: -10px;
    }
    .o-productGrid .m-productCell{
        width: 25%;
        padding: 0px;
    }

}
@media screen and (max-width:768px){
    .o-productGrid{
        margin-left: -15px;
        margin-right: -15px;
    }
    .o-productGrid .m-productCell{
        width: 50%;
        padding: 0px;
    }
    .o-productGrid .m-productCell:nth-child(2n+2) .m-productCell__link{
        border-right: 1px solid $border-grey; 
    }
}
@media screen and (max-width:500px){
    .o-productGrid{
        margin-left: -5px;
        margin-right: -5px;
    }
}

.m-productCell.-top{
    .m-productCell__link{
        flex-direction: row;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
    }
    .m-productCell__number{
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        background: #FFC53D;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;

        &.two{
            background: #8C8C8C;
        }
        &.three{
            background: #D46B08;
        }
    }
    .m-productCell__top{
        width: 70px;
        flex-shrink: 0;
        margin: 0 15px;
    }
    .m-productCell__tagSingle.--circle{
        font-size: 10px;
        height: 32px;
        width: 32px;
    }
    .m-productCell__title{
        padding: 0;
        padding-bottom: 5px;
        width: 100%;
    }
    .m-productCell__priceMain{
        font-size: 16px;
    }
    .m-productCell__deliveryText{
        flex-grow: 1;
    }
    .m-productCell__bottom {
        flex-wrap: wrap; 
    }
}