.o-cartNav{
    background: $white;
    padding: 0;
}
.o-cartNav__container{
    display: flex;
    flex-direction: row;
}
.o-cartNav__single{
    background: transparent;
    position: relative;
    height: 100px;
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &::after{
        content: "";
        height: 17px;
        width: 17px;
        background: url("../img/arrow-icon.png");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 0;
        transform: translate(50%);
    }
}
.o-cartNav__single:last-child::after{
    content: none;
}
.o-cartNav__title{
    @include f-16-primary-regular;
    margin-bottom: 0px;
    margin-left: 15px;
    font-weight: 600;
}
.o-cartNav__number{
    @include f-14-primary-regular;

    border-radius: 50%;
    height: 28px;
    width: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*background: $bg-primary;*/
    color: $white;
    background: #434351;
    z-index: 2;
}

.o-cartNav__single.-active{
    .o-cartNav__title{
        color: $pink-dark;
    }
    .o-cartNav__number{
        background: $pink-dark;
        /*background: $bg-brand;*/
    }
}
.o-cartNav__single.-complete{
    .o-cartNav__title{
        color: $text-primary;
    }
    .o-cartNav__number{
        color: $text-white;
        background: $green;

    }
    &:hover, &:active, &:focus{
        text-decoration: none;
        .o-cartNav__title{
            color: $text-brand-secondary;
        }
    }
}



@media screen and (max-width: 991px){
    .o-cartNav{
        display: none;
    }
}