.o-subCategories{
    margin: auto -5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2rem;
}
.o-subCategories__singleWrap{
    padding: 5px;
    width: 16.666%;
}
.o-subCategories__img{
    height: 80px;
    margin-bottom: 2rem;
}
.o-subCategories__single{
    background: $bg-white;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
    border-radius: $border-radius-primary;
    box-shadow: none;
    border: 1px solid $border-grey;
    position: relative;

    &:hover, &:active, &:focus{
        text-decoration: none;
        box-shadow: $box-shadow-primary;
    }
}
.o-subCategories__title{
    @include f-16-white-regular;
    background: rgba($black, 0.6);
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
}
@media screen and (max-width: 991px){
    .o-subCategories{
        display: none;
    }
}
@media screen and (max-width: 600px){
    .o-subCategories{
        margin: auto -5px;
    }
    .o-subCategories__img{
        height: 40px;
    }
    .o-subCategories__singleWrap{
        width: 33.333%;
        padding: 5px;
    } 
}
@media screen and (max-width: 400px){
    .o-subCategories__singleWrap{
        width: 50%;
    } 
}