.o-sectionPoints{
    padding: 0;
}
.o-sectionPoints__inner{
    background: $bg-brand;
}
.o-sectionPoints__container{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.o-sectionPoints__wrap{
    padding: 4rem 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.o-sectionPoints__single{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    padding: 10px 0;
}
.o-sectionPoints__imgWrap{
    background: rgba($black,0.1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: 42px;
    border-radius: $border-radius-primary;
 
}
.o-sectionPoints__img{
    width: 20px;
    height: 18px;
}
.o-sectionPoints__titleWrap{
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.o-sectionPoints__title, .o-sectionPoints__text{
    font-size: 16px;
    margin: 0;
    color: $text-white;    
    font-weight: 400;
}
.o-sectionPoints__text{
    font-size: 14px;
}
.o-sectionPoints__title{
    font-weight: 500;
}



@media screen and (max-width: 991px){
    .o-sectionPoints{
        display: none;
    }  
}




.o-sectionBanners{
    padding: 0;
    padding-top: 20px;
}
.o-sectionBanners__link{
    width: 100%;
    display: flex;
    margin: 5px 0;
    justify-content: center;
}