.m-navProductsClose{
    display: none;
}


@media screen and (max-width: 991px){
    .m-navProductsClose{
        position: fixed;
        left: 0;
        top: 0px;
        height: 56px;
        width: 56px;
        background: $bg-dark;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        transition: 0.3s ease transform;
        z-index: 9;
        opacity: 0;
        transform: translateX(-100%);

        & span{
            height: 14px;
        }
    }
    .o-navProducts.-active + .m-navProductsClose{
        opacity: 1;
        transform: translateX(260px);
    }
    .o-hero .o-navProducts.-active + .m-navProductsClose{
        opacity: 1;
        transform: translateX(280px);
    }
}