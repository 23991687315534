.m-productDetailMain{

}
.m-productDetailMain__title{
    text-align: left;
    line-height: 1.25;
    margin-bottom: 3rem;
    padding-bottom: 0rem;
    color: $text-primary;
    &::after{
        content: none;
    }
}
.m-productDetailMain__misc{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border: 1px solid $border-grey;
    padding: 10px 20px;
}
.m-productDetailMain__rating{

}
.m-productDetailMain__social{

}
.m-productDetailMain__tags{
    display: flex;
    flex-direction: column;

}
.m-productDetailMain__tagSingle{
    @include f-14-dark-regular;
    color: $bg-primary;
    margin: 0px;
    font-weight: 600;
}
.m-productDetailMain__description{
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
}
.m-productDetailMain__descriptionTitle{
    @include f-14-primary-semibold;
    margin: 0;
    padding-bottom: 2px;
    display: none;
}
.m-productDetailMain__descriptionText{
    @include f-14-dark-regular;
    color: $text-dark;
    margin: 0;
    margin-bottom: 20px;
    a{
        display: flex;
        font-size: 14px;
    }
}

.m-productDetailMain__sizes{
    margin-top: 2rem;
    background: transparent !important;
    border: 1px solid $brand-primary;
}

.m-productDetailMain__variations{
    background: $bg-light;
    padding: 20px;
    border-radius: $border-radius-primary;
    margin-top: 1rem;
}
.m-productDetailMain__priceWrap{
    flex-grow: 1;
    margin-top: 2rem;
    background: $bg-light;
    padding: 2rem;
    display: flex;
}
.m-productDetailMain__cta{
    background: $bg-light;
    border-radius: $border-radius-primary;
    padding: 2rem;
    padding-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0rem;
}
.m-productDetailMain__priceWrapContact{
    display: flex;
    padding-left: 20px;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
}
.m-productDetailMain__priceWrapContactTitle{
    font-size: 16px;
}
.m-productDetailMain__priceWrapContactWrap{
    padding-left: 20px;
}
.m-productDetailMain__priceWrapContactNum{
    font-size: 18px;
    font-weight: 700;
}
.m-productDetailMain__priceOld{
    @include f-14-dark-semibold;
    text-decoration: line-through;
}
.m-productDetailMain__priceMain{
    @include f-30-dark-semibold;
    color: $text-red;
    font-weight: 700;
    margin: 0;
    line-height: 1;
}
.m-productDetailMain__priceDPH{
    @include f-14-dark-regular;
    font-size: 1.3rem;
}
.m-productDetailMain__qty{
    padding-right: 2rem;
    .m-numberStepper{
        background: $bg-white;
    }
}
.m-productDetailMain__button{
    padding: 0 6rem;
    background: $pink;
    flex-grow: 1;

    .m-productDetailMain__icon{
        height: 22px;
        width: 22px;
        margin-right: 10px;
    }
}

.m-productDetailMain__info{
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
}
.m-productDetailMain__stock{
    display: flex;
    flex-direction: column;
    color: #8F8F8F;
}
.m-productDetailMain__stockText{
    @include f-14-dark-regular;
    color: $text-green;

}
.m-productDetailMain__id{
    @include f-14-dark-regular;
    margin-bottom: 0.5rem;
}

@media screen and (max-width:991px){
    .m-productDetailMain{
        padding-top: 20px;
    }
    .m-productDetailMain__cta{
        flex-wrap: wrap;
    }
    .m-productDetailMain__title{
        font-size: 2.4rem;
    }
}

@media screen and (max-width:500px){
    .m-productDetailMain__priceWrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .m-productDetailMain__qty{
        width: 100%;
        padding: 0;
        margin: 10px 0;
    }
    .m-productDetailMain__button{
        width: 100%
    }
    .m-productDetailMain__info{
        flex-direction: column;
    }
    .m-productDetailMain__id{
        margin-top: 5px;
    }
}