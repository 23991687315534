.o-cartDeliveryForm{
    margin-top: -4rem;
    padding-right: 2rem;
}
.o-cartDeliveryForm__title{
    @include f-24-primary-semibold;
    margin: 4rem 0 2rem 0;
    padding-top: 20px;
}
.o-cartDeliveryForm__col{
    margin: 4rem 0;
}

.o-cartDeliveryForm__item{
    padding-bottom: 0px;
    margin: 0;
    margin-bottom: 0px;

    img{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        margin-left: 20px;
    }
}
@media screen and (max-width: 991px){
    .o-cartDeliveryForm{
        padding-right: 0rem;
    }
}
@media screen and (max-width: 768px){
    .o-cartDeliveryForm__title{
        text-align: center;
    }
}