.o-navProducts{
    position: relative;
    z-index: 4;
    width: 100%;
    border-top: 0;
    padding: 0;
    padding-right: calc(100% - 280px);
}
.o-navProducts__accordion{
    background: $bg-white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: $border-radius-primary;
    width: 100%;
    box-shadow: $box-shadow-primary;
}
.mainTop .mainTop__menu .o-navProducts__accordion{
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.5);
}

.o-navProducts__item{
    height: 100%;
    width: 100%;
}
.o-navProducts__link, .o-navProducts__linkDesktop{
    @include f-16-primary-regular;
    color: $text-primary;
    padding: 0 3rem;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &::after{
        content: "";
        position: absolute;
        top: 10px;
        bottom: 10px;
        right: 0;
        width: 0;
        width: 1px;
        background-image: linear-gradient(rgba(255,255,255,0.3) 50%, transparent 0%);
        background-position: right;
        background-size: 1px 5px;
        background-repeat: repeat-y;
    }
    &[aria-expanded="true"]{
        color: $text-brand;
        
        &::after{
            width: 100%;
        }
    }
    &:hover, &:active, &:focus{
        text-decoration: none;

        &::after{
            width: 100%;
        }
    }
}
.o-navProducts__linkDesktop{
    font-size: 1.4rem;
    color: $text-primary;
    padding: 0 2rem;
    height: 37px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    font-weight: 500;

    &:hover, &:active, &:focus{
        color: $text-primary;
        background: $bg-light;
    }
    &:last-child::after{
        content: none;
    }
}
.o-navProducts__collapse{
    background: $bg-white;
    border-top: 1px solid $border-grey;
    width: 100%;
    left: 0px;
    right: 0;
    position: absolute;
}
.o-navProducts__subCategoryWrap{
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.o-navProducts__subCategory{
    padding: 20px 0;
    width: 25%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $border-grey;
    border-bottom: 1px solid $border-grey;

    &:hover, &:active, &:focus{
        text-decoration: none;

        .o-navProducts__subCategoryText{
            color: $text-brand;
        }
    }
}
.o-navProducts__subCategoryImg{
    height: 60px;
}
.o-navProducts__subCategoryText{
    width: 100%;
    height: 100%;
    padding-top: 20px;
    text-align: center;
    @include f-16-primary-semibold;
}

.o-navProducts__link[data-toggle="collapse"] + .o-navProducts__linkDesktop{

        &::before, &::after{
            content: "";
            position: absolute;
            right: 20px;
            top: 50%;
            width: 10px;
            height: 2px;
            left: auto;
            transform: translateY(-50%);
            background: $bg-medium;
        }
        &::after{
            transform: translateY(-50%) rotate(90deg);
        }
        &:hover, &:active, &:focus{
            &::after{
                width: 10px;
            }
        }
        &[aria-expanded="true"]{
            
            &::after{
                background: $bg-grey;
                top: 0;
                left: 0;
                height: 1px;
                width: 100%;
                transform: none;
            } 
        }
    
}


@media screen and (max-width:1400px){
    .o-navProducts__subCategory{
        width: 20%;
        padding: 10px;
    } 
}

@media screen and (min-width:992px){
    .o-navProducts__link{
        display: none;
    }
    .o-navProducts__item{
        height: 100%;
        &:hover, &:active, &:focus{
            & .o-navProducts__linkDesktop + .o-navProducts__collapse{
                display: flex;
            }
        }
    }
    .o-navProducts__collapse{
        background: $bg-white;
        border-top: 1px solid $border-grey;
        width: auto;
        position: absolute;
        top: -46px;
        left: 280px;
        right: 0;

        &.collapsing {
            transition: none;
            display: none;
        }

    }
}

@media screen and (max-width:991px){
    .o-navProducts{
        position: fixed;
        z-index: 9;
        width: 260px;
        transform: translateX(-100%);
        transition: 0.3s ease transform;
        top: 0;
        left: 0;
        height: 100%;
        flex-direction: column;
        overflow-y: auto;
        background: $bg-white;
        padding: 0;
        background: $bg-white;
    }
    .o-navProducts.-active{
        transform: translateX(0%);
        left: 0;
        position: fixed;
        margin: 0;
    }
    .o-navProducts__accordion{
        flex-direction: column;
        height: auto;
        background: $bg-white;
    }
    .o-navProducts__item{
        flex-shrink: 0;
        width: 100%;
        height: auto;
    }
    .o-navProducts__link{
        height: 56px;
        justify-content: flex-start;
        padding: 0 20px;
        position: relative;

        &:hover, &:active, &:focus{
            &::after{
                width: 0px;
            }
        }
        &[data-toggle="collapse"]{
            &::before, &::after{
                content: "";
                position: absolute;
                right: 20px;
                top: 50%;
                width: 10px;
                height: 2px;
                left: auto;
                transform: translateY(-50%);
                background: $bg-medium;
            }
            &::after{
                transform: translateY(-50%) rotate(90deg);
            }
            &:hover, &:active, &:focus{
                &::after{
                    width: 10px;
                }
            }
            &[aria-expanded="true"]{
                
                &::after{
                    background: $bg-grey;
                    top: 0;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    transform: none;
                } 
            }
        }
    }
    .o-navProducts__linkDesktop{
        display: none;
    }
    .o-navProducts__collapse{
        position: relative;
        border: 0;
    }
    .o-navProducts__subCategoryWrap{
        border-bottom: 1px solid $border-grey;
    }
    .o-navProducts__subCategory{
        width: 100%;
        flex-direction: row;
        padding: 5px 20px;
        border: 0;
        min-height: 40px;
    }
    .o-navProducts__subCategoryText{
        padding: 0;
        text-align: left;
        padding-left: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .o-navProducts__subCategoryImg{
        width: 36px;
        height: auto;
    }
}

.o-navProducts__user{
    display: none;
}
@media screen and (max-width:991px){
    .o-navProducts__user{
        display: flex;
    }
}