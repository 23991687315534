.m-productDetailCarousel{
    z-index: 1;
    position: relative;
    margin-bottom: 20px;
    padding-right: 20px;
}
.m-productDetailCarousel__tags{
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
}

.m-productDetailCarousel__gallery{

}
.m-productDetailCarousel__content{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.m-productDetailCarousel__wrap{

}
.m-productDetailCarousel__slide{

}
.m-productDetailCarousel__figure{
    padding-bottom: 100%;
    position: relative;
}
.m-productDetailCarousel__link{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.m-productDetailCarousel__preloader{
    z-index: -1;
}
.m-productDetailCarousel__img{
    display: none;
    max-width: 100%;
    max-height: 100%;
}
.m-productDetailCarousel__img.swiper-lazy-loaded{
    display: block;
}
.m-productDetailCarousel__figcaption{
    display: none;
}   

.m-productDetailCarousel__swiperNav{
    z-index: 2;
    
    .swiper-pagination-bullet-active{
        background: $bg-brand;
    }
}
.m-productDetailCarousel__swiperButton{
    background: rgba(0,0,0,0.5);
    border-radius: $border-radius-primary;
    height: 38px;
    width: 38px;
    z-index: 5;

    &::after{
        content: "";
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjIwNyAxMiI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik05LjM0NSw5LjA1OWExLjIyNCwxLjIyNCwwLDAsMSwxLjcxNC0xLjcxNGw0LjksNC45YTEuMjI0LDEuMjI0LDAsMCwxLDAsMS43MTRsLTQuOSw0LjlhMS4yMjQsMS4yMjQsMCwwLDEtMS43MTQtMS43MTRMMTMuMzczLDEzLjEsOS4zMzMsOS4wNTlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOS4xIC03LjEpIi8+PC9zdmc+"); 
        height: 12px;
        width: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    &.-prev{
        transform: rotate(180deg);
    }
}


@media screen and (max-width:991px){
    .m-productDetailCarousel__figure{
        padding-bottom: 50%;
    }
    .m-productDetailCarousel{
        margin-top: 10px;
    }
}
@media screen and (max-width:500px){
    .m-productDetailCarousel__figure{
        padding-bottom: 60%;
    }
}