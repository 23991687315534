.a-icon{
    display: block;
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: center center;
}

.a-icon.-hamburger{
    height: 2px;
    width: 18px;
    background: $bg-white;
    display: block;
    @include center(both);
    position: relative;
    transition: 0.3s all ease;

    &::before, &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: $bg-white;
        transform: translate(0%,-300%);
        transition: 0.3s all ease; 
    }
    &::after{
        transform: translate(0%,300%);     
    }

    &.-white{
        background: $bg-white;
        
        &::before, &::after{
            background: $bg-white;
        }
    }
}



.a-icon.-cheveron{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjIwNyAxMiI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzI0MjcyZDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik05LjM0NSw5LjA1OWExLjIyNCwxLjIyNCwwLDAsMSwxLjcxNC0xLjcxNGw0LjksNC45YTEuMjI0LDEuMjI0LDAsMCwxLDAsMS43MTRsLTQuOSw0LjlhMS4yMjQsMS4yMjQsMCwwLDEtMS43MTQtMS43MTRMMTMuMzczLDEzLjEsOS4zMzMsOS4wNTlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOS4xIC03LjEpIi8+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjIwNyAxMiI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik05LjM0NSw5LjA1OWExLjIyNCwxLjIyNCwwLDAsMSwxLjcxNC0xLjcxNGw0LjksNC45YTEuMjI0LDEuMjI0LDAsMCwxLDAsMS43MTRsLTQuOSw0LjlhMS4yMjQsMS4yMjQsMCwwLDEtMS43MTQtMS43MTRMMTMuMzczLDEzLjEsOS4zMzMsOS4wNTlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOS4xIC03LjEpIi8+PC9zdmc+"); 
    }
}

.a-icon.-user{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMS4zMzMgLTEuMzMzKSI+PHBhdGggZD0iTTEyLjMzMywxLjMzM2ExMS4xNDcsMTEuMTQ3LDAsMCwwLTExLDExLDExLjA1NCwxMS4wNTQsMCwwLDAsNC40MDUsOC43MmMwLC4wMjYsMCwuMDU0LDAsLjA4MWguMTEyYTEwLjY2OCwxMC42NjgsMCwwLDAsMTIuOTc1LDBoLjExMmMwLS4wMjYsMC0uMDU0LDAtLjA4MWExMS4wNTIsMTEuMDUyLDAsMCwwLDQuNDA1LTguNzIsMTEuMTQ3LDExLjE0NywwLDAsMC0xMS0xMVptLTQuMSwxOC43NDNhNC42NzUsNC42NzUsMCwwLDEsNC4xLTIuMjQ0LDQuNzQ4LDQuNzQ4LDAsMCwxLDQuMSwyLjI0OSw4LjQ3OSw4LjQ3OSwwLDAsMS00LjEsMS4wNTFoMGE4LjUzNyw4LjUzNywwLDAsMS00LjE1LTEuMDc5bC4wNDUuMDIzWm0xMC0xLjI4NGE3LjA2Miw3LjA2MiwwLDAsMC01Ljg5Mi0zLjE2LDYuOTgxLDYuOTgxLDAsMCwwLTUuOTA4LDMuMTQ1LDguNzkxLDguNzkxLDAsMCwxLTIuODkyLTYuNDQ1LDguOTE2LDguOTE2LDAsMCwxLDguOC04LjgsOC45MTYsOC45MTYsMCwwLDEsOC44LDguOCw4LjgsOC44LDAsMCwxLTIuOTA3LDYuNDU5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSIgZmlsbD0iIzA2MDcwZSIvPjxwYXRoIGQ9Ik05LjQ3Myw0LjY2N0EzLjcyMiwzLjcyMiwwLDAsMCw1LjY2Nyw4LjQ3M2EzLjcyMiwzLjcyMiwwLDAsMCwzLjgwNiwzLjgwNiwzLjcyMiwzLjcyMiwwLDAsMCwzLjgwNi0zLjgwNkEzLjcyMiwzLjcyMiwwLDAsMCw5LjQ3Myw0LjY2N1ptMCw1LjQzN0ExLjU2LDEuNTYsMCwwLDEsNy44NDEsOC40NzMsMS41NiwxLjU2LDAsMCwxLDkuNDczLDYuODQxLDEuNTYsMS41NiwwLDAsMSwxMS4xLDguNDczLDEuNTYsMS41NiwwLDAsMSw5LjQ3MywxMC4xWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi44NiAyLjIpIiBmaWxsPSIjMDYwNzBlIi8+PC9nPjwvc3ZnPg=="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMS4zMzMgLTEuMzMzKSI+PHBhdGggZD0iTTEyLjMzMywxLjMzM2ExMS4xNDcsMTEuMTQ3LDAsMCwwLTExLDExLDExLjA1NCwxMS4wNTQsMCwwLDAsNC40MDUsOC43MmMwLC4wMjYsMCwuMDU0LDAsLjA4MWguMTEyYTEwLjY2OCwxMC42NjgsMCwwLDAsMTIuOTc1LDBoLjExMmMwLS4wMjYsMC0uMDU0LDAtLjA4MWExMS4wNTIsMTEuMDUyLDAsMCwwLDQuNDA1LTguNzIsMTEuMTQ3LDExLjE0NywwLDAsMC0xMS0xMVptLTQuMSwxOC43NDNhNC42NzUsNC42NzUsMCwwLDEsNC4xLTIuMjQ0LDQuNzQ4LDQuNzQ4LDAsMCwxLDQuMSwyLjI0OSw4LjQ3OSw4LjQ3OSwwLDAsMS00LjEsMS4wNTFoMGE4LjUzNyw4LjUzNywwLDAsMS00LjE1LTEuMDc5bC4wNDUuMDIzWm0xMC0xLjI4NGE3LjA2Miw3LjA2MiwwLDAsMC01Ljg5Mi0zLjE2LDYuOTgxLDYuOTgxLDAsMCwwLTUuOTA4LDMuMTQ1LDguNzkxLDguNzkxLDAsMCwxLTIuODkyLTYuNDQ1LDguOTE2LDguOTE2LDAsMCwxLDguOC04LjgsOC45MTYsOC45MTYsMCwwLDEsOC44LDguOCw4LjgsOC44LDAsMCwxLTIuOTA3LDYuNDU5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik05LjQ3Myw0LjY2N0EzLjcyMiwzLjcyMiwwLDAsMCw1LjY2Nyw4LjQ3M2EzLjcyMiwzLjcyMiwwLDAsMCwzLjgwNiwzLjgwNiwzLjcyMiwzLjcyMiwwLDAsMCwzLjgwNi0zLjgwNkEzLjcyMiwzLjcyMiwwLDAsMCw5LjQ3Myw0LjY2N1ptMCw1LjQzN0ExLjU2LDEuNTYsMCwwLDEsNy44NDEsOC40NzMsMS41NiwxLjU2LDAsMCwxLDkuNDczLDYuODQxLDEuNTYsMS41NiwwLDAsMSwxMS4xLDguNDczLDEuNTYsMS41NiwwLDAsMSw5LjQ3MywxMC4xWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi44NiAyLjIpIiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg=="); 
    }
    &.-grey{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMS4zMzMgLTEuMzMzKSI+PHBhdGggZD0iTTEyLjMzMywxLjMzM2ExMS4xNDcsMTEuMTQ3LDAsMCwwLTExLDExLDExLjA1NCwxMS4wNTQsMCwwLDAsNC40MDUsOC43MmMwLC4wMjYsMCwuMDU0LDAsLjA4MWguMTEyYTEwLjY2OCwxMC42NjgsMCwwLDAsMTIuOTc1LDBoLjExMmMwLS4wMjYsMC0uMDU0LDAtLjA4MWExMS4wNTIsMTEuMDUyLDAsMCwwLDQuNDA1LTguNzIsMTEuMTQ3LDExLjE0NywwLDAsMC0xMS0xMVptLTQuMSwxOC43NDNhNC42NzUsNC42NzUsMCwwLDEsNC4xLTIuMjQ0LDQuNzQ4LDQuNzQ4LDAsMCwxLDQuMSwyLjI0OSw4LjQ3OSw4LjQ3OSwwLDAsMS00LjEsMS4wNTFoMGE4LjUzNyw4LjUzNywwLDAsMS00LjE1LTEuMDc5bC4wNDUuMDIzWm0xMC0xLjI4NGE3LjA2Miw3LjA2MiwwLDAsMC01Ljg5Mi0zLjE2LDYuOTgxLDYuOTgxLDAsMCwwLTUuOTA4LDMuMTQ1LDguNzkxLDguNzkxLDAsMCwxLTIuODkyLTYuNDQ1LDguOTE2LDguOTE2LDAsMCwxLDguOC04LjgsOC45MTYsOC45MTYsMCwwLDEsOC44LDguOCw4LjgsOC44LDAsMCwxLTIuOTA3LDYuNDU5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSIgZmlsbD0iIzhkOGQ4ZCIvPjxwYXRoIGQ9Ik05LjQ3Myw0LjY2N0EzLjcyMiwzLjcyMiwwLDAsMCw1LjY2Nyw4LjQ3M2EzLjcyMiwzLjcyMiwwLDAsMCwzLjgwNiwzLjgwNiwzLjcyMiwzLjcyMiwwLDAsMCwzLjgwNi0zLjgwNkEzLjcyMiwzLjcyMiwwLDAsMCw5LjQ3Myw0LjY2N1ptMCw1LjQzN0ExLjU2LDEuNTYsMCwwLDEsNy44NDEsOC40NzMsMS41NiwxLjU2LDAsMCwxLDkuNDczLDYuODQxLDEuNTYsMS41NiwwLDAsMSwxMS4xLDguNDczLDEuNTYsMS41NiwwLDAsMSw5LjQ3MywxMC4xWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi44NiAyLjIpIiBmaWxsPSIjOGQ4ZDhkIi8+PC9nPjwvc3ZnPg=="); 
    }
    &.-blue{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjQ1MDYgMEM1LjUzMTg0IDAuMTA4NjMyIDQuOTc0MzMgMy42MjMwOCA2LjAyMzUxIDcuNjc2NjZDNS44NTg5MyA3Ljc4NTMgNS41MjM2MSA4LjE3MTU0IDUuNTc5MTUgOC44MTkzMUM1Ljc0MzczIDEwLjAwODIgNi4yNDE1OCAxMC4zMzIxIDYuNTE3MjQgMTAuMzMyMUM2LjYyODMzIDExLjE0MjggNy4wMTkyIDExLjk5NTggNy40MDU5NiAxMi41MzY5QzcuNjgxNjIgMTIuOTE1MSA3Ljc4NDQ4IDEzLjQwMiA3Ljc4NDQ4IDEzLjg4ODhDNy43ODQ0OCAxNC4zMjEzIDcuNzkwNjUgMTQuMDQ1NyA3LjczNTExIDE0LjUzMjZDNy42Nzk1NiAxNC42ODk1IDcuNjAxMzkgMTQuODMyMyA3LjUwNDcgMTQuOTY3MUM3LjYxNzg1IDE1LjM4MzUgOC4yNjk5OCAxNy4zNjUgMTAuNSAxNy4zNjVDMTIuNzgzNSAxNy4zNjUgMTMuMzk2NiAxNS4yMjY2IDEzLjQ2MjQgMTQuOTY3MUMxMy4zNTk1IDE0LjgzNDMgMTMuMjc3MiAxNC42ODk1IDEzLjIxNTUgMTQuNTMyNkMxMy4xNiAxMy45MzkxIDEzLjE0OTcgMTQuMjE0NyAxMy4xNDk3IDEzLjcyNzlDMTMuMTQ5NyAxMy4yOTU0IDEzLjMyMjUgMTIuOTI1MiAxMy41NDQ3IDEyLjYwMTNDMTMuOTMxNCAxMi4wNjAyIDE0LjMwNTggMTEuMTQyOCAxNC40MTY5IDEwLjMzMjFDMTQuNzUwMiAxMC4zMzIxIDE1LjI0MzkgMTAuMDA4MiAxNS4zNTUgOC44MTkzMUMxNS40MTA2IDguMTcxNTQgMTUuMTQ5MyA3Ljc4NTMgMTQuOTI3MSA3LjY3NjY2QzE1LjQ4MDUgNi4xNjM4NiAxNi41MjE1IDEuNDY4NTQgMTIuOTg1MSAwLjk4MTcxMkMxMi41OTg0IDAuMzMzOTQzIDExLjY2NjQgMCAxMC40NTA2IDBaTTE0LjMzNDYgMTUuNjkxM0MxMy44OTIzIDE2Ljg0MiAxMi43MjE4IDE4LjM5NSAxMC41IDE4LjM5NUM4LjI3NjE2IDE4LjM5NSA3LjEwOTcyIDE2Ljg3MDIgNi42NDg5IDE1LjcwNzRDNC40NjAwMyAxNy4wMjUxIDAgMTcuNDI5NCAwIDIySDIxQzIxIDE3LjMxMjcgMTYuNTYwNSAxNi45OTA5IDE0LjMzNDYgMTUuNjkxM1oiIGZpbGw9IiMzRDk5RkYiLz4KPC9zdmc+Cg=="); 
    }
}

.a-icon.-cart{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNC40NDUgMjIiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiMyNDI3MmQ7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyNyAtMzMpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTI2LjIsNC40ODVBMS4yMjgsMS4yMjgsMCwwLDAsMjUuMjIyLDRIMlY2LjQ0NEg0LjgxMWw0LjYsMTIuNjRhMS4yMiwxLjIyLDAsMCwwLDEuMTQ4LjhoMTFWMTcuNDQ0SDExLjQxMUwxMC41MjEsMTVIMjIuNzc4YTEuMjI0LDEuMjI0LDAsMCwwLDEuMTc2LS44ODdMMjYuNCw1LjU1N0ExLjIyOCwxLjIyOCwwLDAsMCwyNi4yLDQuNDg1Wm0tNC4zNDMsOC4wN0g5LjYzNEw3LjQxMiw2LjQ0NEgyMy42WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyOSAyOSkiLz48Y2lyY2xlIGNsYXNzPSJhIiBjeD0iMS44MzMiIGN5PSIxLjgzMyIgcj0iMS44MzMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMTguNDQ0IDUxLjMzMykiLz48Y2lyY2xlIGNsYXNzPSJhIiBjeD0iMS44MzMiIGN5PSIxLjgzMyIgcj0iMS44MzMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMTEuMTExIDUxLjMzMykiLz48L2c+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAxOSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjcwMjYgNi4wMDI1NkMxNy42OTU5IDUuOTEzMDIgMTcuNjIwOSA1Ljg0Mzc1IDE3LjUzMTIgNS44NDM3NUgxNC40Mzc1VjQuOTg0MzhDMTQuNDM3NSAyLjIzNTkyIDEyLjIwMTQgMCA5LjQ1MzEgMEM2LjcwNDgyIDAgNC40Njg3MyAyLjIzNTkyIDQuNDY4NzMgNC45ODQzOFY1Ljg0Mzc1SDEuMzc0OThDMS4yODUyNiA1Ljg0Mzc1IDEuMjEwMzIgNS45MTMwMiAxLjIwMzYyIDYuMDAyNTZMMC4wMDA0OTI3NzcgMjEuODE1MUMtMC4wMDMxMTY2IDIxLjg2MjcgMC4wMTMyMTE1IDIxLjkwOTggMC4wNDU4Njc4IDIxLjk0NDhDMC4wNzgzNTIxIDIxLjk4MDEgMC4xMjQwNzEgMjIgMC4xNzE4NTIgMjJIMTguNzM0NEMxOC43ODIxIDIyIDE4LjgyNzkgMjEuOTgwMSAxOC44NjAzIDIxLjk0NUMxOC44OTI4IDIxLjkwOTkgMTguOTA5MyAyMS44NjI4IDE4LjkwNTcgMjEuODE1MkwxNy43MDI2IDYuMDAyNTZaTTUuODQzNzMgNC45ODQzOEM1Ljg0MzczIDIuOTk0MjMgNy40NjI3OSAxLjM3NSA5LjQ1MzEgMS4zNzVDMTEuNDQzNCAxLjM3NSAxMy4wNjI1IDIuOTk0MjMgMTMuMDYyNSA0Ljk4NDM4VjUuODQzNzVINS44NDM3M1Y0Ljk4NDM4Wk01LjE1NjIzIDEwLjY1NjJDNC4yMDgzNCAxMC42NTYyIDMuNDM3NDggOS44ODUyMiAzLjQzNzQ4IDguOTM3NUMzLjQzNzQ4IDguMjUxNTUgMy43ODEyMyA3LjYzMzQ4IDQuNDY4NzMgNy4zNjIyN1Y4LjkzNzVDNC40Njg3MyA5LjMxNjY2IDQuNzc3MjQgOS42MjUgNS4xNTYyMyA5LjYyNUM1LjUzNTIxIDkuNjI1IDUuODQzNzMgOS4zMTY2NiA1Ljg0MzczIDguOTM3NVY3LjM2MzNDNi41MzEyMyA3LjYzNDUyIDYuODc0OTggOC4yNTcyMiA2Ljg3NDk4IDguOTM3NUM2Ljg3NDk4IDkuODg1MjIgNi4xMDQxMiAxMC42NTYyIDUuMTU2MjMgMTAuNjU2MlpNMTMuNzUgMTAuNjU2MkMxMi44MDIxIDEwLjY1NjIgMTIuMDMxMiA5Ljg4NTIyIDEyLjAzMTIgOC45Mzc1QzEyLjAzMTIgOC4yNTE1NSAxMi4zNzUgNy42MzM0OCAxMy4wNjI1IDcuMzYyMjdWOC45Mzc1QzEzLjA2MjUgOS4zMTY2NiAxMy4zNzEgOS42MjUgMTMuNzUgOS42MjVDMTQuMTI5IDkuNjI1IDE0LjQzNzUgOS4zMTY2NiAxNC40Mzc1IDguOTM3NVY3LjM2MzNDMTUuMTI1IDcuNjM0NTIgMTUuNDY4NyA4LjI1NzIyIDE1LjQ2ODcgOC45Mzc1QzE1LjQ2ODcgOS44ODUyMiAxNC42OTc5IDEwLjY1NjIgMTMuNzUgMTAuNjU2MloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo="); 
    }
    &.-grey{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNC40NDUiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyNC40NDUgMjIiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC00KSI+PHBhdGggZD0iTTI2LjIsNC40ODVBMS4yMjgsMS4yMjgsMCwwLDAsMjUuMjIyLDRIMlY2LjQ0NEg0LjgxMWw0LjYsMTIuNjRhMS4yMiwxLjIyLDAsMCwwLDEuMTQ4LjhoMTFWMTcuNDQ0SDExLjQxMUwxMC41MjEsMTVIMjIuNzc4YTEuMjI0LDEuMjI0LDAsMCwwLDEuMTc2LS44ODdMMjYuNCw1LjU1N0ExLjIyOCwxLjIyOCwwLDAsMCwyNi4yLDQuNDg1Wm0tNC4zNDMsOC4wN0g5LjYzNEw3LjQxMiw2LjQ0NEgyMy42WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSIgZmlsbD0iIzhkOGQ4ZCIvPjxjaXJjbGUgY3g9IjEuODMzIiBjeT0iMS44MzMiIHI9IjEuODMzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMC41NTYgMjIuMzMzKSIgZmlsbD0iIzhkOGQ4ZCIvPjxjaXJjbGUgY3g9IjEuODMzIiBjeT0iMS44MzMiIHI9IjEuODMzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNy44ODkgMjIuMzMzKSIgZmlsbD0iIzhkOGQ4ZCIvPjwvZz48L3N2Zz4="); 
    }
    &.-red{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAxOSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjcwMjYgNi4wMDI1NkMxNy42OTU5IDUuOTEzMDIgMTcuNjIwOSA1Ljg0Mzc1IDE3LjUzMTIgNS44NDM3NUgxNC40Mzc1VjQuOTg0MzhDMTQuNDM3NSAyLjIzNTkyIDEyLjIwMTQgMCA5LjQ1MzEgMEM2LjcwNDgyIDAgNC40Njg3MyAyLjIzNTkyIDQuNDY4NzMgNC45ODQzOFY1Ljg0Mzc1SDEuMzc0OThDMS4yODUyNiA1Ljg0Mzc1IDEuMjEwMzIgNS45MTMwMiAxLjIwMzYyIDYuMDAyNTZMMC4wMDA0OTI3NzcgMjEuODE1MUMtMC4wMDMxMTY2IDIxLjg2MjcgMC4wMTMyMTE1IDIxLjkwOTggMC4wNDU4Njc4IDIxLjk0NDhDMC4wNzgzNTIxIDIxLjk4MDEgMC4xMjQwNzEgMjIgMC4xNzE4NTIgMjJIMTguNzM0NEMxOC43ODIxIDIyIDE4LjgyNzkgMjEuOTgwMSAxOC44NjAzIDIxLjk0NUMxOC44OTI4IDIxLjkwOTkgMTguOTA5MyAyMS44NjI4IDE4LjkwNTcgMjEuODE1MkwxNy43MDI2IDYuMDAyNTZaTTUuODQzNzMgNC45ODQzOEM1Ljg0MzczIDIuOTk0MjMgNy40NjI3OSAxLjM3NSA5LjQ1MzEgMS4zNzVDMTEuNDQzNCAxLjM3NSAxMy4wNjI1IDIuOTk0MjMgMTMuMDYyNSA0Ljk4NDM4VjUuODQzNzVINS44NDM3M1Y0Ljk4NDM4Wk01LjE1NjIzIDEwLjY1NjJDNC4yMDgzNCAxMC42NTYyIDMuNDM3NDggOS44ODUyMiAzLjQzNzQ4IDguOTM3NUMzLjQzNzQ4IDguMjUxNTUgMy43ODEyMyA3LjYzMzQ4IDQuNDY4NzMgNy4zNjIyN1Y4LjkzNzVDNC40Njg3MyA5LjMxNjY2IDQuNzc3MjQgOS42MjUgNS4xNTYyMyA5LjYyNUM1LjUzNTIxIDkuNjI1IDUuODQzNzMgOS4zMTY2NiA1Ljg0MzczIDguOTM3NVY3LjM2MzNDNi41MzEyMyA3LjYzNDUyIDYuODc0OTggOC4yNTcyMiA2Ljg3NDk4IDguOTM3NUM2Ljg3NDk4IDkuODg1MjIgNi4xMDQxMiAxMC42NTYyIDUuMTU2MjMgMTAuNjU2MlpNMTMuNzUgMTAuNjU2MkMxMi44MDIxIDEwLjY1NjIgMTIuMDMxMiA5Ljg4NTIyIDEyLjAzMTIgOC45Mzc1QzEyLjAzMTIgOC4yNTE1NSAxMi4zNzUgNy42MzM0OCAxMy4wNjI1IDcuMzYyMjdWOC45Mzc1QzEzLjA2MjUgOS4zMTY2NiAxMy4zNzEgOS42MjUgMTMuNzUgOS42MjVDMTQuMTI5IDkuNjI1IDE0LjQzNzUgOS4zMTY2NiAxNC40Mzc1IDguOTM3NVY3LjM2MzNDMTUuMTI1IDcuNjM0NTIgMTUuNDY4NyA4LjI1NzIyIDE1LjQ2ODcgOC45Mzc1QzE1LjQ2ODcgOS44ODUyMiAxNC42OTc5IDEwLjY1NjIgMTMuNzUgMTAuNjU2MloiIGZpbGw9IiNEMDAxMDkiLz4KPC9zdmc+Cg=="); 
    }
}

.a-icon.-search{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNy45OTkgMTgiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiMyNDI3MmQ7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNOS43LDE3LjM5NGE3LjY1LDcuNjUsMCwwLDAsNC43MS0xLjYyM0wxOC42MzksMjAsMjAsMTguNjRsLTQuMjMtNC4yM0E3LjY4OCw3LjY4OCwwLDEsMCw5LjcsMTcuMzk0Wm0wLTEzLjQ3QTUuNzczLDUuNzczLDAsMSwxLDMuOTI0LDkuNyw1Ljc3OSw1Ljc3OSwwLDAsMSw5LjcsMy45MjRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMikiLz48L3N2Zz4="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNy45OTkgMTgiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNOS43LDE3LjM5NGE3LjY1LDcuNjUsMCwwLDAsNC43MS0xLjYyM0wxOC42MzksMjAsMjAsMTguNjRsLTQuMjMtNC4yM0E3LjY4OCw3LjY4OCwwLDEsMCw5LjcsMTcuMzk0Wm0wLTEzLjQ3QTUuNzczLDUuNzczLDAsMSwxLDMuOTI0LDkuNyw1Ljc3OSw1Ljc3OSwwLDAsMSw5LjcsMy45MjRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMikiLz48L3N2Zz4="); 
    }
}

.a-icon.-home{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMC4wMDYgMzAiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiMyNDI3MmQ7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTguNTMxLDI5VjIxLjVoLTNWMjlhMywzLDAsMCwxLTMsM2gtNC41YTMsMywwLDAsMS0zLTNWMTcuNjE2bC0uNDUuNDVhMS41LDEuNSwwLDEsMS0yLjEtMi4xM2wxMy41LTEzLjVhMS41LDEuNSwwLDAsMSwyLjEsMGwxMy41LDEzLjVhMS41LDEuNSwwLDAsMS0yLjEsMi4xM2wtLjQ1LS40NVYyOWEzLDMsMCwwLDEtMywzaC00LjVhMywzLDAsMCwxLTMtM1ptNy41LDBWMTQuNjE2bC05LTktOSw5VjI5aDQuNVYyMS41YTMuMDA5LDMuMDA5LDAsMCwxLDMtM2gzYTMsMywwLDAsMSwzLDNWMjlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMS45NDUgLTIuMDA0KSIvPjwvc3ZnPg=="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMC4wMDYgMzAiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTguNTMxLDI5VjIxLjVoLTNWMjlhMywzLDAsMCwxLTMsM2gtNC41YTMsMywwLDAsMS0zLTNWMTcuNjE2bC0uNDUuNDVhMS41LDEuNSwwLDEsMS0yLjEtMi4xM2wxMy41LTEzLjVhMS41LDEuNSwwLDAsMSwyLjEsMGwxMy41LDEzLjVhMS41LDEuNSwwLDAsMS0yLjEsMi4xM2wtLjQ1LS40NVYyOWEzLDMsMCwwLDEtMywzaC00LjVhMywzLDAsMCwxLTMtM1ptNy41LDBWMTQuNjE2bC05LTktOSw5VjI5aDQuNVYyMS41YTMuMDA5LDMuMDA5LDAsMCwxLDMtM2gzYTMsMywwLDAsMSwzLDNWMjlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMS45NDUgLTIuMDA0KSIvPjwvc3ZnPg=="); 
    }
}

.a-icon.-address{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyNyI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzI0MjcyZDt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTMpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTYsN0gyNHYzSDZabTAsNkgxOS40ODV2M0g2Wm0wLDZoOXYzSDZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyIDIpIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0zMC41LDNIMy41QTEuNSwxLjUsMCwwLDAsMiw0LjV2MjRBMS41LDEuNSwwLDAsMCwzLjUsMzBoMjdBMS41LDEuNSwwLDAsMCwzMiwyOC41VjQuNUExLjUsMS41LDAsMCwwLDMwLjUsM1pNMjksMjdINVY2SDI5WiIvPjwvZz48L3N2Zz4="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyNyI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTMpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTYsN0gyNHYzSDZabTAsNkgxOS40ODV2M0g2Wm0wLDZoOXYzSDZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyIDIpIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0zMC41LDNIMy41QTEuNSwxLjUsMCwwLDAsMiw0LjV2MjRBMS41LDEuNSwwLDAsMCwzLjUsMzBoMjdBMS41LDEuNSwwLDAsMCwzMiwyOC41VjQuNUExLjUsMS41LDAsMCwwLDMwLjUsM1pNMjksMjdINVY2SDI5WiIvPjwvZz48L3N2Zz4="); 
    }
}


.a-icon.-star{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNi4wMzcgMTYiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNmZmIzMDA7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNNS44OTMsMTEuOTI0bC0xLjQ1Nyw1LjFhLjguOCwwLDAsMCwxLjIxNi44ODdMMTAuMDE4LDE1bDQuMzY2LDIuOTExYS44LjgsMCwwLDAsMS4yMTYtLjg4N2wtMS40NTctNS4xLDMuNTkyLTIuODc0YS44LjgsMCwwLDAtLjM3LTEuNDE3TDEyLjk1OCw2LjksMTAuNzM2LDIuNDUzYS44MzUuODM1LDAsMCwwLTEuNDM0LDBMNy4wNzgsNi45LDIuNjcsNy42MzNBLjguOCwwLDAsMCwyLjMsOS4wNVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC0yLjA0NikiLz48L3N2Zz4="); 
    
    &.-empty{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNi4wMzUgMTYiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNmZmIzMDA7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNNS44OTMsMTEuOTIzbC0xLjQ1Nyw1LjFhLjguOCwwLDAsMCwxLjIxNi44ODdMMTAuMDE4LDE1bDQuMzY2LDIuOTExYS44LjgsMCwwLDAsMS4yMTYtLjg4N2wtMS40NTctNS4xTDE3LjczNSw5LjA1YS44LjgsMCwwLDAtLjM3LTEuNDE3TDEyLjk1OCw2LjksMTAuNzM2LDIuNDUzYS44MzUuODM1LDAsMCwwLTEuNDM0LDBMNy4wNzgsNi45LDIuNjcsNy42MzNBLjguOCwwLDAsMCwyLjMsOS4wNVpNNy43NDQsOC40MTJhLjguOCwwLDAsMCwuNTg1LS40MzJMMTAuMDE4LDQuNiwxMS43MDYsNy45OGEuOC44LDAsMCwwLC41ODUuNDMybDMuMDM5LjUwN0wxMi43MjMsMTFhLjguOCwwLDAsMC0uMjcuODQ3bDEsMy41MTYtMy0yYS44LjgsMCwwLDAtLjg4OSwwbC0zLDIsMS0zLjUxNkEuOC44LDAsMCwwLDcuMzEyLDExTDQuNzA1LDguOTE4bDMuMDM5LS41MDZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMi4wNDYpIi8+PC9zdmc+"); 
    }
}

.a-icon.-checkCircle{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzI0MjcyZDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xMSwyMGE5LDksMCwxLDEsOS05QTksOSwwLDAsMSwxMSwyMFptMC0xLjhBNy4yLDcuMiwwLDEsMCwzLjgsMTEsNy4yLDcuMiwwLDAsMCwxMSwxOC4yWk04LjkzLDEwLjM3bDEuMTcsMS4xNjEsMi45Ny0yLjk3YS45LjksMCwwLDEsMS4yNiwxLjI3OGwtMy42LDMuNmEuOS45LDAsMCwxLTEuMjYsMGwtMS44LTEuOGEuOS45LDAsMCwxLDEuMjYtMS4yNzhaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMikiLz48L3N2Zz4="); 
    
    &.-green{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzM0YWQ3Yzt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xMSwyMGE5LDksMCwxLDEsOS05QTksOSwwLDAsMSwxMSwyMFptMC0xLjhBNy4yLDcuMiwwLDEsMCwzLjgsMTEsNy4yLDcuMiwwLDAsMCwxMSwxOC4yWk04LjkzLDEwLjM3bDEuMTcsMS4xNjEsMi45Ny0yLjk3YS45LjksMCwwLDEsMS4yNiwxLjI3OGwtMy42LDMuNmEuOS45LDAsMCwxLTEuMjYsMGwtMS44LTEuOGEuOS45LDAsMCwxLDEuMjYtMS4yNzhaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMikiLz48L3N2Zz4="); 
    }
}

.a-icon.-envelope{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNy41IDE0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojMjQyNzJkO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTMuNzUsNGgxNEExLjc1LDEuNzUsMCwwLDEsMTkuNSw1Ljc1djEwLjVBMS43NSwxLjc1LDAsMCwxLDE3Ljc1LDE4aC0xNEExLjc1LDEuNzUsMCwwLDEsMiwxNi4yNVY1Ljc1QTEuNzU1LDEuNzU1LDAsMCwxLDMuNzUsNFptMTQsMi45NThWNS43NWgtMTRWNi45NThsNywzLjVabTAsMS45Ni02LjYwNiwzLjNhLjg3NS44NzUsMCwwLDEtLjc4OCwwTDMuNzUsOC45MTdWMTYuMjVoMTRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtNCkiLz48L3N2Zz4="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNy41IDE0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTMuNzUsNGgxNEExLjc1LDEuNzUsMCwwLDEsMTkuNSw1Ljc1djEwLjVBMS43NSwxLjc1LDAsMCwxLDE3Ljc1LDE4aC0xNEExLjc1LDEuNzUsMCwwLDEsMiwxNi4yNVY1Ljc1QTEuNzU1LDEuNzU1LDAsMCwxLDMuNzUsNFptMTQsMi45NThWNS43NWgtMTRWNi45NThsNywzLjVabTAsMS45Ni02LjYwNiwzLjNhLjg3NS44NzUsMCwwLDEtLjc4OCwwTDMuNzUsOC45MTdWMTYuMjVoMTRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtNCkiLz48L3N2Zz4="); 
    }
}

.a-icon.-phone{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzI0MjcyZDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xNS4yNDgsMTcuMjI4bDEuMjg0LTIuNTY4YTEuMiwxLjIsMCwwLDEsMS40NC0uNmw3LjIsMi40QTEuMiwxLjIsMCwwLDEsMjYsMTcuNnY2QTIuNCwyLjQsMCwwLDEsMjMuNiwyNkgyMS4yQTE5LjIsMTkuMiwwLDAsMSwyLDYuOFY0LjRBMi40MDcsMi40MDcsMCwwLDEsNC40LDJoNmExLjIsMS4yLDAsMCwxLDEuMTQuODE2bDIuNCw3LjJhMS4yLDEuMiwwLDAsMS0uNiwxLjQ1MmwtMi41OCwxLjI4NGExMi4wNiwxMi4wNiwwLDAsMCw0LjQ3Niw0LjQ3NlpNOS41MzYsNC40SDQuNFY2LjhBMTYuOCwxNi44LDAsMCwwLDIxLjIsMjMuNmgyLjRWMTguNDY0bC01LjQtMS44LTEuMzQ0LDIuNzEyYTEuMiwxLjIsMCwwLDEtMS41Ni41NTIsMTQuNDQ4LDE0LjQ0OCwwLDAsMS03LjIyNC03LjIxMiwxLjIsMS4yLDAsMCwxLC41NTItMS41NmwyLjcxMi0xLjM2OFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC0yKSIvPjwvc3ZnPg=="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xNS4yNDgsMTcuMjI4bDEuMjg0LTIuNTY4YTEuMiwxLjIsMCwwLDEsMS40NC0uNmw3LjIsMi40QTEuMiwxLjIsMCwwLDEsMjYsMTcuNnY2QTIuNCwyLjQsMCwwLDEsMjMuNiwyNkgyMS4yQTE5LjIsMTkuMiwwLDAsMSwyLDYuOFY0LjRBMi40MDcsMi40MDcsMCwwLDEsNC40LDJoNmExLjIsMS4yLDAsMCwxLDEuMTQuODE2bDIuNCw3LjJhMS4yLDEuMiwwLDAsMS0uNiwxLjQ1MmwtMi41OCwxLjI4NGExMi4wNiwxMi4wNiwwLDAsMCw0LjQ3Niw0LjQ3NlpNOS41MzYsNC40SDQuNFY2LjhBMTYuOCwxNi44LDAsMCwwLDIxLjIsMjMuNmgyLjRWMTguNDY0bC01LjQtMS44LTEuMzQ0LDIuNzEyYTEuMiwxLjIsMCwwLDEtMS41Ni41NTIsMTQuNDQ4LDE0LjQ0OCwwLDAsMS03LjIyNC03LjIxMiwxLjIsMS4yLDAsMCwxLC41NTItMS41NmwyLjcxMi0xLjM2OFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC0yKSIvPjwvc3ZnPg=="); 
    }
}

.a-icon.-personalInfo{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxMS4yIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojNGU1MDU1O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTIuNyw4LjlINi4yYS43LjcsMCwwLDAsLjctLjdWNC43QS43LjcsMCwwLDAsNi4yLDRIMi43YS43LjcsMCwwLDAtLjcuN1Y4LjJBLjcuNywwLDAsMCwyLjcsOC45Wm0uNy0zLjVINS41VjcuNUgzLjRabS0uNyw5LjhINi4yYS43LjcsMCwwLDAsLjctLjdWMTFhLjcuNywwLDAsMC0uNy0uN0gyLjdBLjcuNywwLDAsMCwyLDExdjMuNUEuNy43LDAsMCwwLDIuNywxNS4yWm0uNy0zLjVINS41djIuMUgzLjRaTTguMyw0SDE2VjUuNEg4LjNabTAsMi44aDYuM1Y4LjJIOC4zWm0wLDMuNUgxNnYxLjRIOC4zWm0wLDIuOGg2LjN2MS40SDguM1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC00KSIvPjwvc3ZnPg=="); 
    
    &.-red{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxMS4yIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojYzQxZTNkO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTIuNyw4LjlINi4yYS43LjcsMCwwLDAsLjctLjdWNC43QS43LjcsMCwwLDAsNi4yLDRIMi43YS43LjcsMCwwLDAtLjcuN1Y4LjJBLjcuNywwLDAsMCwyLjcsOC45Wm0uNy0zLjVINS41VjcuNUgzLjRabS0uNyw5LjhINi4yYS43LjcsMCwwLDAsLjctLjdWMTFhLjcuNywwLDAsMC0uNy0uN0gyLjdBLjcuNywwLDAsMCwyLDExdjMuNUEuNy43LDAsMCwwLDIuNywxNS4yWm0uNy0zLjVINS41djIuMUgzLjRaTTguMyw0SDE2VjUuNEg4LjNabTAsMi44aDYuM1Y4LjJIOC4zWm0wLDMuNUgxNnYxLjRIOC4zWm0wLDIuOGg2LjN2MS40SDguM1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC00KSIvPjwvc3ZnPg=="); 
    }
}

.a-icon.-calendar{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzRlNTA1NTt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTIuNywxNkgxNS4zYS43LjcsMCwwLDAsLjctLjdWMy40YS43LjcsMCwwLDAtLjctLjdIMTIuNVYySDExLjF2LjdINi45VjJINS41di43SDIuN2EuNy43LDAsMCwwLS43LjdWMTUuM0EuNy43LDAsMCwwLDIuNywxNlptLjctMS40di03SDE0LjZ2N1pNNS41LDQuMXYuN0g2LjlWNC4xaDQuMnYuN2gxLjRWNC4xaDIuMVY2LjJIMy40VjQuMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiLz48cGF0aCBjbGFzcz0iYSIgZD0iTTcsMTJIOC40djEuNEg3Wm0wLDIuOEg4LjR2MS40SDdaTTkuOCwxMmgxLjR2MS40SDkuOFptMCwyLjhoMS40djEuNEg5LjhaTTEyLjYsMTJIMTR2MS40SDEyLjZabTAsMi44SDE0djEuNEgxMi42WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEuNSAtMykiLz48L2c+PC9zdmc+"); 
    
    &.-red{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2M0MWUzZDt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTIuNywxNkgxNS4zYS43LjcsMCwwLDAsLjctLjdWMy40YS43LjcsMCwwLDAtLjctLjdIMTIuNVYySDExLjF2LjdINi45VjJINS41di43SDIuN2EuNy43LDAsMCwwLS43LjdWMTUuM0EuNy43LDAsMCwwLDIuNywxNlptLjctMS40di03SDE0LjZ2N1pNNS41LDQuMXYuN0g2LjlWNC4xaDQuMnYuN2gxLjRWNC4xaDIuMVY2LjJIMy40VjQuMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiLz48cGF0aCBjbGFzcz0iYSIgZD0iTTcsMTJIOC40djEuNEg3Wm0wLDIuOEg4LjR2MS40SDdaTTkuOCwxMmgxLjR2MS40SDkuOFptMCwyLjhoMS40djEuNEg5LjhaTTEyLjYsMTJIMTR2MS40SDEyLjZabTAsMi44SDE0djEuNEgxMi42WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEuNSAtMykiLz48L2c+PC9zdmc+"); 
    }
}

.a-icon.-logOut{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMS42NjcgMTQiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiM0ZTUwNTU7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00IC0zKSI+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xNi41NTYsMTEuMTExLDEzLjQ0NCw4djIuMzMzSDh2MS41NTZoNS40NDR2Mi4zMzNaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC44ODkgLTEuMTExKSIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNNCwzLjc3OFYxNi4yMjJBLjc3OC43NzgsMCwwLDAsNC43NzgsMTdoNS40NDRWMTUuNDQ0SDUuNTU2VjQuNTU2aDQuNjY3VjNINC43NzhBLjc3OC43NzgsMCwwLDAsNCwzLjc3OFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDApIi8+PC9nPjwvc3ZnPg=="); 
    
    &.-red{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMS42NjcgMTQiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNjNDFlM2Q7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00IC0zKSI+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xNi41NTYsMTEuMTExLDEzLjQ0NCw4djIuMzMzSDh2MS41NTZoNS40NDR2Mi4zMzNaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC44ODkgLTEuMTExKSIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNNCwzLjc3OFYxNi4yMjJBLjc3OC43NzgsMCwwLDAsNC43NzgsMTdoNS40NDRWMTUuNDQ0SDUuNTU2VjQuNTU2aDQuNjY3VjNINC43NzhBLjc3OC43NzgsMCwwLDAsNCwzLjc3OFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDApIi8+PC9nPjwvc3ZnPg=="); 
    }
}

.a-icon.-crossCircle{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzI0MjcyZDt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTE0Ljc5Myw3Ljc5MywxMiwxMC41ODYsOS4yMDcsNy43OTMsNy43OTMsOS4yMDcsMTAuNTg2LDEyLDcuNzkzLDE0Ljc5M2wxLjQxNCwxLjQxNEwxMiwxMy40MTRsMi43OTMsMi43OTMsMS40MTQtMS40MTRMMTMuNDE0LDEybDIuNzkzLTIuNzkzWiIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTIsMkExMCwxMCwwLDEsMCwyMiwxMiwxMC4wMTEsMTAuMDExLDAsMCwwLDEyLDJabTAsMThhOCw4LDAsMSwxLDgtOEE4LjAwOSw4LjAwOSwwLDAsMSwxMiwyMFoiLz48L2c+PC9zdmc+"); 
    
    &.-red{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2M0MWUzZDt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTE0Ljc5Myw3Ljc5MywxMiwxMC41ODYsOS4yMDcsNy43OTMsNy43OTMsOS4yMDcsMTAuNTg2LDEyLDcuNzkzLDE0Ljc5M2wxLjQxNCwxLjQxNEwxMiwxMy40MTRsMi43OTMsMi43OTMsMS40MTQtMS40MTRMMTMuNDE0LDEybDIuNzkzLTIuNzkzWiIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTIsMkExMCwxMCwwLDEsMCwyMiwxMiwxMC4wMTEsMTAuMDExLDAsMCwwLDEyLDJabTAsMThhOCw4LDAsMSwxLDgtOEE4LjAwOSw4LjAwOSwwLDAsMSwxMiwyMFoiLz48L2c+PC9zdmc+"); 
    }
}

.a-icon.-cross{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMy40MTQgMTMuNDE0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojMjQyNzJkO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTYuNzA3LDE4LjcwNywxMiwxMy40MTRsNS4yOTMsNS4yOTMsMS40MTQtMS40MTRMMTMuNDE0LDEybDUuMjkzLTUuMjkzTDE3LjI5Myw1LjI5MywxMiwxMC41ODYsNi43MDcsNS4yOTMsNS4yOTMsNi43MDcsMTAuNTg2LDEyLDUuMjkzLDE3LjI5M1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01LjI5MyAtNS4yOTMpIi8+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMy40MTQgMTMuNDE0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTYuNzA3LDE4LjcwNywxMiwxMy40MTRsNS4yOTMsNS4yOTMsMS40MTQtMS40MTRMMTMuNDE0LDEybDUuMjkzLTUuMjkzTDE3LjI5Myw1LjI5MywxMiwxMC41ODYsNi43MDcsNS4yOTMsNS4yOTMsNi43MDcsMTAuNTg2LDEyLDUuMjkzLDE3LjI5M1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01LjI5MyAtNS4yOTMpIi8+PC9zdmc+"); 
    }
    &.-red{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMy40MTQgMTMuNDE0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojYzQxZTNkO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTYuNzA3LDE4LjcwNywxMiwxMy40MTRsNS4yOTMsNS4yOTMsMS40MTQtMS40MTRMMTMuNDE0LDEybDUuMjkzLTUuMjkzTDE3LjI5Myw1LjI5MywxMiwxMC41ODYsNi43MDcsNS4yOTMsNS4yOTMsNi43MDcsMTAuNTg2LDEyLDUuMjkzLDE3LjI5M1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01LjI5MyAtNS4yOTMpIi8+PC9zdmc+"); 
    }
}

.a-icon.-store{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzI0MjcyZDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0zMiwxMWExLjQ5MiwxLjQ5MiwwLDAsMC0uMjE1LS43NzJsLTQuNS03LjVBMS41LDEuNSwwLDAsMCwyNiwySDhhMS41LDEuNSwwLDAsMC0xLjI4NS43MjdsLTQuNSw3LjVBMS40OTIsMS40OTIsMCwwLDAsMiwxMWE1Ljk1NCw1Ljk1NCwwLDAsMCwxLjUsMy45MjdWMzAuNUExLjUsMS41LDAsMCwwLDUsMzJIMjlhMS41LDEuNSwwLDAsMCwxLjUtMS41VjE0LjkyN0E1Ljk1NCw1Ljk1NCwwLDAsMCwzMiwxMVptLTMuMDI0LjM3N0EzLDMsMCwwLDEsMjMsMTFhMS4zMjYsMS4zMjYsMCwwLDAtLjA1OS0uMjg4bC4wMy0uMDA2TDIxLjgzLDVoMy4zMjFaTTE1LjIyOCw1SDE4Ljc3bDEuMjE5LDYuMWEyLjk5MSwyLjk5MSwwLDAsMS01Ljk3OSwwWk04Ljg0OSw1SDEyLjE3bC0xLjE0LDUuNzA2LjAzLjAwNkExLjI0NSwxLjI0NSwwLDAsMCwxMSwxMWEzLDMsMCwwLDEtNS45NzYuMzc3Wk0xNCwyOVYyM2g2djZabTksMFYyMS41QTEuNSwxLjUsMCwwLDAsMjEuNSwyMGgtOUExLjUsMS41LDAsMCwwLDExLDIxLjVWMjlINi41VjE2Ljc4N2E1Ljg0OCw1Ljg0OCwwLDAsMCw2LTEuODIzLDUuOTkyLDUuOTkyLDAsMCwwLDksMCw1Ljg0OCw1Ljg0OCwwLDAsMCw2LDEuODIzVjI5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIi8+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0zMiwxMWExLjQ5MiwxLjQ5MiwwLDAsMC0uMjE1LS43NzJsLTQuNS03LjVBMS41LDEuNSwwLDAsMCwyNiwySDhhMS41LDEuNSwwLDAsMC0xLjI4NS43MjdsLTQuNSw3LjVBMS40OTIsMS40OTIsMCwwLDAsMiwxMWE1Ljk1NCw1Ljk1NCwwLDAsMCwxLjUsMy45MjdWMzAuNUExLjUsMS41LDAsMCwwLDUsMzJIMjlhMS41LDEuNSwwLDAsMCwxLjUtMS41VjE0LjkyN0E1Ljk1NCw1Ljk1NCwwLDAsMCwzMiwxMVptLTMuMDI0LjM3N0EzLDMsMCwwLDEsMjMsMTFhMS4zMjYsMS4zMjYsMCwwLDAtLjA1OS0uMjg4bC4wMy0uMDA2TDIxLjgzLDVoMy4zMjFaTTE1LjIyOCw1SDE4Ljc3bDEuMjE5LDYuMWEyLjk5MSwyLjk5MSwwLDAsMS01Ljk3OSwwWk04Ljg0OSw1SDEyLjE3bC0xLjE0LDUuNzA2LjAzLjAwNkExLjI0NSwxLjI0NSwwLDAsMCwxMSwxMWEzLDMsMCwwLDEtNS45NzYuMzc3Wk0xNCwyOVYyM2g2djZabTksMFYyMS41QTEuNSwxLjUsMCwwLDAsMjEuNSwyMGgtOUExLjUsMS41LDAsMCwwLDExLDIxLjVWMjlINi41VjE2Ljc4N2E1Ljg0OCw1Ljg0OCwwLDAsMCw2LTEuODIzLDUuOTkyLDUuOTkyLDAsMCwwLDksMCw1Ljg0OCw1Ljg0OCwwLDAsMCw2LDEuODIzVjI5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIi8+PC9zdmc+"); 
    }
}

.a-icon.-car{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyOC41Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojNGU1MDU1O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTI4Ljg5NCw4LjQ0M0ExLjUsMS41LDAsMCwwLDI3LjUsNy41aC02di0zQTEuNSwxLjUsMCwwLDAsMjAsM0gzLjVBMS41LDEuNSwwLDAsMCwyLDQuNVYyNGExLjUsMS41LDAsMCwwLDEuNSwxLjVINS4wNzZBNC45NjQsNC45NjQsMCwwLDAsNSwyNi4yNWE1LjI1LDUuMjUsMCwwLDAsMTAuNSwwLDQuOTY0LDQuOTY0LDAsMCwwLS4wNzYtLjc1aDMuMTUzYTQuOTY0LDQuOTY0LDAsMCwwLS4wNzYuNzUsNS4yNSw1LjI1LDAsMCwwLDEwLjUsMCw0Ljk2NCw0Ljk2NCwwLDAsMC0uMDc3LS43NUgzMC41QTEuNSwxLjUsMCwwLDAsMzIsMjRWMTYuNWExLjUsMS41LDAsMCwwLS4xMDYtLjU1NmwtMy03LjVaTTIxLjUsMTAuNWg0Ljk4NGwxLjgsNC41SDIxLjVabS0xMS4yNSwxOGEyLjI1LDIuMjUsMCwxLDEsMi4yNS0yLjI1QTIuMjUzLDIuMjUzLDAsMCwxLDEwLjI1LDI4LjVabTguMjUtNkgxMy45MTFhNS4yMiw1LjIyLDAsMCwwLTcuNDExLjA4OFYyMi41SDVWNkgxOC41Wm01LjI1LDZBMi4yNSwyLjI1LDAsMSwxLDI2LDI2LjI1LDIuMjUzLDIuMjUzLDAsMCwxLDIzLjc1LDI4LjVabTMuNjYxLTZBNS4yMjksNS4yMjksMCwwLDAsMjMuNzUsMjFhNS4xNzcsNS4xNzcsMCwwLDAtMi4yNS41MjhWMThIMjl2NC41WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTMpIi8+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyOC41Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTI4Ljg5NCw4LjQ0M0ExLjUsMS41LDAsMCwwLDI3LjUsNy41aC02di0zQTEuNSwxLjUsMCwwLDAsMjAsM0gzLjVBMS41LDEuNSwwLDAsMCwyLDQuNVYyNGExLjUsMS41LDAsMCwwLDEuNSwxLjVINS4wNzZBNC45NjQsNC45NjQsMCwwLDAsNSwyNi4yNWE1LjI1LDUuMjUsMCwwLDAsMTAuNSwwLDQuOTY0LDQuOTY0LDAsMCwwLS4wNzYtLjc1aDMuMTUzYTQuOTY0LDQuOTY0LDAsMCwwLS4wNzYuNzUsNS4yNSw1LjI1LDAsMCwwLDEwLjUsMCw0Ljk2NCw0Ljk2NCwwLDAsMC0uMDc3LS43NUgzMC41QTEuNSwxLjUsMCwwLDAsMzIsMjRWMTYuNWExLjUsMS41LDAsMCwwLS4xMDYtLjU1NmwtMy03LjVaTTIxLjUsMTAuNWg0Ljk4NGwxLjgsNC41SDIxLjVabS0xMS4yNSwxOGEyLjI1LDIuMjUsMCwxLDEsMi4yNS0yLjI1QTIuMjUzLDIuMjUzLDAsMCwxLDEwLjI1LDI4LjVabTguMjUtNkgxMy45MTFhNS4yMiw1LjIyLDAsMCwwLTcuNDExLjA4OFYyMi41SDVWNkgxOC41Wm01LjI1LDZBMi4yNSwyLjI1LDAsMSwxLDI2LDI2LjI1LDIuMjUzLDIuMjUzLDAsMCwxLDIzLjc1LDI4LjVabTMuNjYxLTZBNS4yMjksNS4yMjksMCwwLDAsMjMuNzUsMjFhNS4xNzcsNS4xNzcsMCwwLDAtMi4yNS41MjhWMThIMjl2NC41WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTMpIi8+PC9zdmc+"); 
    }
}

.a-icon.-card{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzRlNTA1NTt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTQpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTMwLjUsNEgzLjVBMS41LDEuNSwwLDAsMCwyLDUuNXYyMUExLjUsMS41LDAsMCwwLDMuNSwyOGgyN0ExLjUsMS41LDAsMCwwLDMyLDI2LjVWNS41QTEuNSwxLjUsMCwwLDAsMzAuNSw0Wk0yOSw3djNINVY3Wk01LDI1VjE2SDI5djlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwKSIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNNiwxNGg5djNINloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIgNSkiLz48L2c+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTQpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTMwLjUsNEgzLjVBMS41LDEuNSwwLDAsMCwyLDUuNXYyMUExLjUsMS41LDAsMCwwLDMuNSwyOGgyN0ExLjUsMS41LDAsMCwwLDMyLDI2LjVWNS41QTEuNSwxLjUsMCwwLDAsMzAuNSw0Wk0yOSw3djNINVY3Wk01LDI1VjE2SDI5djlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwKSIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNNiwxNGg5djNINloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIgNSkiLz48L2c+PC9zdmc+"); 
    }
}

.a-icon.-box{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyOS45ODUiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiM0ZTUwNTU7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMzEuOTg5LDEwLjkxM2ExLjY2NCwxLjY2NCwwLDAsMC0uMDQzLS4zMTIsMS41MTcsMS41MTcsMCwwLDAtLjA2My0uMTU0LDEuNTQ0LDEuNTQ0LDAsMCwwLS4wODctLjIsMS41LDEuNSwwLDAsMC0uMTItLjE1OCwxLjgzNiwxLjgzNiwwLDAsMC0uMTE3LS4xNSwxLjQ0NiwxLjQ0NiwwLDAsMC0uMTYtLjEyNiwxLjY0NSwxLjY0NSwwLDAsMC0uMTM5LS4xMDhsLS4wMTUtLjAwNi0uMDE4LS4wMTNMMTcuNjk0LDIuMmExLjUsMS41LDAsMCwwLTEuNDU1LDBMMi43NzEsOS42ODNhLjAyMS4wMjEsMCwwLDEtLjAxMi4wMWMtLjAwNiwwLS4wMTQsMC0uMDE5LjAwOWExLjM4MSwxLjM4MSwwLDAsMC0uMTguMTQxYy0uMDM4LjAzLS4wODMuMDU3LS4xMTcuMDlhMS42MjMsMS42MjMsMCwwLDAtLjE1My4yYy0uMDI3LjAzOS0uMDYxLjA3Mi0uMDg0LjExMmExLjQ0MywxLjQ0MywwLDAsMC0uMS4yNDhjLS4wMTQuMDM3LS4wMzUuMDczLS4wNDUuMTExYTEuMzcsMS4zNywwLDAsMC0uMDQ1LjMyMWMwLC4wMjYtLjAxMS4wNDctLjAxMS4wNzJWMjNhMS41LDEuNSwwLDAsMCwuNzczLDEuMzExbDEzLjQ2Nyw3LjQ4aDBsLjAzLjAxN2ExLjQ2MiwxLjQ2MiwwLDAsMCwuMi4wODEsMS4yMjcsMS4yMjcsMCwwLDAsLjE0Ni4wNTgsMS41MTksMS41MTksMCwwLDAsLjc1OSwwLDEuNDczLDEuNDczLDAsMCwwLC4xNDYtLjA1OCwxLjMsMS4zLDAsMCwwLC4yLS4wODFsLjAzLS4wMTdoMGwxMy40NjctNy40OEExLjUsMS41LDAsMCwwLDMyLDIzdi0xMmMwLS4wMjktLjAwOS0uMDUyLS4wMTEtLjA4Wk0xNy4wNDIsMTYuOGwtNC4yODEtMi4zNzlMMjMuMTY4LDguNjUyLDI3LjQxMywxMVpNMTYuOTcsNS4yMjUsMTkuNyw2LjczNSw5LjM3NywxMi41NDUsNi41ODgsMTFaTTUsMTMuNTU0bDMsMS42Nzl2My4xMTJMMTEsMjBWMTYuOTExbDQuNSwyLjUxNywwLDguNTE4TDUsMjIuMTEyWk0xOC41LDI3Ljk0N3YtOC41MkwyOSwxMy41NTN2OC41NTlMMTguNSwyNy45NDdaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMi4wMDkpIi8+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyOS45ODUiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMzEuOTg5LDEwLjkxM2ExLjY2NCwxLjY2NCwwLDAsMC0uMDQzLS4zMTIsMS41MTcsMS41MTcsMCwwLDAtLjA2My0uMTU0LDEuNTQ0LDEuNTQ0LDAsMCwwLS4wODctLjIsMS41LDEuNSwwLDAsMC0uMTItLjE1OCwxLjgzNiwxLjgzNiwwLDAsMC0uMTE3LS4xNSwxLjQ0NiwxLjQ0NiwwLDAsMC0uMTYtLjEyNiwxLjY0NSwxLjY0NSwwLDAsMC0uMTM5LS4xMDhsLS4wMTUtLjAwNi0uMDE4LS4wMTNMMTcuNjk0LDIuMmExLjUsMS41LDAsMCwwLTEuNDU1LDBMMi43NzEsOS42ODNhLjAyMS4wMjEsMCwwLDEtLjAxMi4wMWMtLjAwNiwwLS4wMTQsMC0uMDE5LjAwOWExLjM4MSwxLjM4MSwwLDAsMC0uMTguMTQxYy0uMDM4LjAzLS4wODMuMDU3LS4xMTcuMDlhMS42MjMsMS42MjMsMCwwLDAtLjE1My4yYy0uMDI3LjAzOS0uMDYxLjA3Mi0uMDg0LjExMmExLjQ0MywxLjQ0MywwLDAsMC0uMS4yNDhjLS4wMTQuMDM3LS4wMzUuMDczLS4wNDUuMTExYTEuMzcsMS4zNywwLDAsMC0uMDQ1LjMyMWMwLC4wMjYtLjAxMS4wNDctLjAxMS4wNzJWMjNhMS41LDEuNSwwLDAsMCwuNzczLDEuMzExbDEzLjQ2Nyw3LjQ4aDBsLjAzLjAxN2ExLjQ2MiwxLjQ2MiwwLDAsMCwuMi4wODEsMS4yMjcsMS4yMjcsMCwwLDAsLjE0Ni4wNTgsMS41MTksMS41MTksMCwwLDAsLjc1OSwwLDEuNDczLDEuNDczLDAsMCwwLC4xNDYtLjA1OCwxLjMsMS4zLDAsMCwwLC4yLS4wODFsLjAzLS4wMTdoMGwxMy40NjctNy40OEExLjUsMS41LDAsMCwwLDMyLDIzdi0xMmMwLS4wMjktLjAwOS0uMDUyLS4wMTEtLjA4Wk0xNy4wNDIsMTYuOGwtNC4yODEtMi4zNzlMMjMuMTY4LDguNjUyLDI3LjQxMywxMVpNMTYuOTcsNS4yMjUsMTkuNyw2LjczNSw5LjM3NywxMi41NDUsNi41ODgsMTFaTTUsMTMuNTU0bDMsMS42Nzl2My4xMTJMMTEsMjBWMTYuOTExbDQuNSwyLjUxNywwLDguNTE4TDUsMjIuMTEyWk0xOC41LDI3Ljk0N3YtOC41MkwyOSwxMy41NTN2OC41NTlMMTguNSwyNy45NDdaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMi4wMDkpIi8+PC9zdmc+"); 
    }
    &.-green{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOS43OTciIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOS43OTcgMTkiPg0KICA8cGF0aCBpZD0ib3Blbi1ib3giIGQ9Ik0xOS42MjYsOS4zMiwxNy41NzMsNi44NDhsMS42ODMtMi4zNTVhLjc0My43NDMsMCwwLDAtLjMxNy0xLjExNkwxMi4yLjU1N2EuNzQyLjc0MiwwLDAsMC0uODkxLjI1M0w5LjksMi43ODYsOC40ODcuODFBLjc0Mi43NDIsMCwwLDAsNy42LjU1N0wuODYsMy4zNzhBLjc0Mi43NDIsMCwwLDAsLjU0Myw0LjQ5NEwyLjIyNiw2Ljg0OC4xNzIsOS4zMmEuNzQyLjc0MiwwLDAsMCwuMjg0LDEuMTU5TDIuNDIsMTEuM3Y0LjYzNmEuNzQyLjc0MiwwLDAsMCwuNDU2LjY4NWw2LjczNywyLjgyYS43NDIuNzQyLDAsMCwwLC41NzMsMGw2LjczNy0yLjgyYS43NDIuNzQyLDAsMCwwLC40NTYtLjY4NVYxMS4zbDEuOTY0LS44MjJhLjc0Mi43NDIsMCwwLDAsLjI4NC0xLjE1OVpNMTIuMTc1LDIuMTU1bDUuMzMyLDIuMjMyLTEuMTMsMS41ODJMMTEuMDQ0LDMuNzM3Wk05LjksOC45LDUuMDg0LDYuODgzLDkuOSw0Ljg2N2w0LjgxNSwyLjAxNlpNNy42MjQsMi4xNTVsMS4xMywxLjU4MkwzLjQyMiw1Ljk2OSwyLjI5Miw0LjM4OFpNMy4zODIsNy43NzksOC42ODgsMTAsNy4yNiwxMS43MTgsMS45NTUsOS41Wm0xMi41MTIsNy42NjRMOS45LDE3Ljk1MywzLjksMTUuNDQzVjExLjkyMkw3LjE5MywxMy4zYS43NDIuNzQyLDAsMCwwLC44NTctLjIxTDkuOSwxMC44NjRsMS44NDksMi4yMjRhLjc0Mi43NDIsMCwwLDAsLjg1Ny4yMWwzLjI4OS0xLjM3N1ptLTMuMzU1LTMuNzI2TDExLjExMSwxMGw1LjMwNS0yLjIyMUwxNy44NDQsOS41Wm0wLDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0wLjAwMSAtMC41KSIgZmlsbD0iIzY0YTgyMCIvPg0KPC9zdmc+DQo="); 
    }
}

.a-icon.-wallet{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyNS41Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojNGU1MDU1O308L3N0eWxlPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMykiPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTYsMTJoM3Y0LjVIMTZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3IDQuNSkiLz48cGF0aCBjbGFzcz0iYSIgZD0iTTMwLjUsOUgyOVY0LjVBMS41LDEuNSwwLDAsMCwyNy41LDNINi41QTQuMzc0LDQuMzc0LDAsMCwwLDIsNy41VjI0YTQuMzc0LDQuMzc0LDAsMCwwLDQuNSw0LjVoMjRBMS41LDEuNSwwLDAsMCwzMiwyN1YxMC41QTEuNSwxLjUsMCwwLDAsMzAuNSw5Wk02LjUsNkgyNlY5SDYuNTE4QTEuMzc4LDEuMzc4LDAsMCwxLDUsNy41LDEuMzczLDEuMzczLDAsMCwxLDYuNSw2Wk0yOSwyNS41SDYuNTE4QTEuMzc4LDEuMzc4LDAsMCwxLDUsMjRWMTEuNzVBNC44MSw0LjgxLDAsMCwwLDYuNSwxMkgyOVoiLz48L2c+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyNS41Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMykiPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTYsMTJoM3Y0LjVIMTZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3IDQuNSkiLz48cGF0aCBjbGFzcz0iYSIgZD0iTTMwLjUsOUgyOVY0LjVBMS41LDEuNSwwLDAsMCwyNy41LDNINi41QTQuMzc0LDQuMzc0LDAsMCwwLDIsNy41VjI0YTQuMzc0LDQuMzc0LDAsMCwwLDQuNSw0LjVoMjRBMS41LDEuNSwwLDAsMCwzMiwyN1YxMC41QTEuNSwxLjUsMCwwLDAsMzAuNSw5Wk02LjUsNkgyNlY5SDYuNTE4QTEuMzc4LDEuMzc4LDAsMCwxLDUsNy41LDEuMzczLDEuMzczLDAsMCwxLDYuNSw2Wk0yOSwyNS41SDYuNTE4QTEuMzc4LDEuMzc4LDAsMCwxLDUsMjRWMTEuNzVBNC44MSw0LjgxLDAsMCwwLDYuNSwxMkgyOVoiLz48L2c+PC9zdmc+"); 
    }
}

.a-icon.-dots{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMTBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yek0xOCAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6TTYgMTBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yeiIvPjwvc3ZnPg=="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiA0Ij4NCiAgPGRlZnM+DQogICAgPHN0eWxlPg0KICAgICAgLmNscy0xIHsNCiAgICAgICAgZmlsbDogI2ZmZjsNCiAgICAgIH0NCiAgICA8L3N0eWxlPg0KICA8L2RlZnM+DQogIDxwYXRoIGlkPSJieC1kb3RzLWhvcml6b250YWwtcm91bmRlZCIgY2xhc3M9ImNscy0xIiBkPSJNMTIsMTBhMiwyLDAsMSwwLDIsMkEyLjAwNiwyLjAwNiwwLDAsMCwxMiwxMFptNiwwYTIsMiwwLDEsMCwyLDJBMi4wMDYsMi4wMDYsMCwwLDAsMTgsMTBaTTYsMTBhMiwyLDAsMSwwLDIsMkEyLjAwNiwyLjAwNiwwLDAsMCw2LDEwWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQgLTEwKSIvPg0KPC9zdmc+DQo="); 
    }
}

.a-icon.-info{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzRlNTA1NTt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTEyLDJBMTAsMTAsMCwxLDAsMjIsMTIsMTAuMDExLDEwLjAxMSwwLDAsMCwxMiwyWm0wLDE4YTgsOCwwLDEsMSw4LThBOC4wMDksOC4wMDksMCwwLDEsMTIsMjBaIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xMSwxMWgydjZIMTFabTAtNGgyVjlIMTFaIi8+PC9nPjwvc3ZnPg=="); 
}



.a-icon.-facebook{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzNiNTk5ODt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0yMCwzSDRBMSwxLDAsMCwwLDMsNFYyMGExLDEsMCwwLDAsMSwxaDguNjE1VjE0LjA0SDEwLjI3N1YxMS4zMTVoMi4zMzh2LTJhMy4yNzYsMy4yNzYsMCwwLDEsMy41LTMuNTkyLDE5LjU1NywxOS41NTcsMCwwLDEsMi4wOTUuMTA3VjguMjVIMTYuNzc1Yy0xLjEyOCwwLTEuMzQ4LjUzOC0xLjM0OCwxLjMyNVYxMS4zMWgyLjdsLS4zNSwyLjcyNUgxNS40MjZWMjFIMjBhMSwxLDAsMCwwLDEtMVY0QTEsMSwwLDAsMCwyMCwzWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMgLTMpIi8+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZmZmZjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0yMCwzSDRBMSwxLDAsMCwwLDMsNFYyMGExLDEsMCwwLDAsMSwxaDguNjE1VjE0LjA0SDEwLjI3N1YxMS4zMTVoMi4zMzh2LTJhMy4yNzYsMy4yNzYsMCwwLDEsMy41LTMuNTkyLDE5LjU1NywxOS41NTcsMCwwLDEsMi4wOTUuMTA3VjguMjVIMTYuNzc1Yy0xLjEyOCwwLTEuMzQ4LjUzOC0xLjM0OCwxLjMyNVYxMS4zMWgyLjdsLS4zNSwyLjcyNUgxNS40MjZWMjFIMjBhMSwxLDAsMCwwLDEtMVY0QTEsMSwwLDAsMCwyMCwzWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMgLTMpIi8+PC9zdmc+");
    }
}

.a-icon.-twitter{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzQ5YWFlYjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xOC43NSwyMUg1LjI1QTIuMjUxLDIuMjUxLDAsMCwxLDMsMTguNzVWNS4yNUEyLjI1MSwyLjI1MSwwLDAsMSw1LjI1LDNoMTMuNUEyLjI1MSwyLjI1MSwwLDAsMSwyMSw1LjI1djEzLjVBMi4yNTEsMi4yNTEsMCwwLDEsMTguNzUsMjFaTTkuOTEyLDE2LjU3MmE2LjUyNCw2LjUyNCwwLDAsMCw2LjU2Ni02LjU2NWMwLS4xLDAtLjItLjAwNi0uM2E0LjY4NSw0LjY4NSwwLDAsMCwxLjE1Mi0xLjIsNC42NzQsNC42NzQsMCwwLDEtMS4zMjcuMzY1QTIuMzI0LDIuMzI0LDAsMCwwLDE3LjMxNCw3LjZhNC41ODMsNC41ODMsMCwwLDEtMS40NjcuNTU5LDIuMzA5LDIuMzA5LDAsMCwwLTMuOTkzLDEuNTgsMi40OCwyLjQ4LDAsMCwwLC4wNi41MjZBNi41NTEsNi41NTEsMCwwLDEsNy4xNTcsNy44NTJhMi4zMTksMi4zMTksMCwwLDAsLjcxNiwzLjA4MSwyLjM0NiwyLjM0NiwwLDAsMS0xLjA0NC0uMjg4di4wMzJBMi4zMTIsMi4zMTIsMCwwLDAsOC42OCwxMi45NGEyLjI1MywyLjI1MywwLDAsMS0uNjA4LjA4MSwyLjIsMi4yLDAsMCwxLS40MzItLjA0MSwyLjMwNywyLjMwNywwLDAsMCwyLjE1NSwxLjYsNC42MjksNC42MjksMCwwLDEtMi44NjYuOTksNC41NjMsNC41NjMsMCwwLDEtLjU1LS4wMzEsNi41NjUsNi41NjUsMCwwLDAsMy41MzMsMS4wMzEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zIC0zKSIvPjwvc3ZnPg=="); 
}

.a-icon.-googlePlus{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOC4wMDEgMTgiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNjNDFlM2Q7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMjAsMTAuOTdhOSw5LDAsMCwwLTE4LDBjMC0uMDA5LDAtLjAxNSwwLS4wMjNWMTFIMnYuMDQ5YS4xNjYuMTY2LDAsMCwxLDAtLjAyMSw5LDksMCwwLDAsMTgsMGMwLC4wMDgsMCwuMDE1LDAsLjAyM3YtLjEwNkMyMCwxMC45NTYsMjAsMTAuOTYyLDIwLDEwLjk3Wk04LjU0OCwxNS4wOTJhNC4wOTEsNC4wOTEsMCwwLDEsMC04LjE4MiwzLjkyMSwzLjkyMSwwLDAsMSwyLjczOSwxLjA2OEwxMC4xMjMsOS4xMDdBMi4yNDIsMi4yNDIsMCwwLDAsOC41NDgsOC41YTIuNSwyLjUsMCwwLDAsMCw1LjAwOSwyLjEyLDIuMTIsMCwwLDAsMi4yNS0xLjcyN0g4LjU0N1YxMC4yNTZoMy44MjdhMy40NDIsMy40NDIsMCwwLDEsLjEuODM4QTMuNzc1LDMuNzc1LDAsMCwxLDguNTQ4LDE1LjA5MlptOS40MDYtMy42ODNIMTYuMzE4djEuNjM2SDE1LjA5MVYxMS40MDlIMTMuNDU0VjEwLjE4MmgxLjYzN1Y4LjU0NmgxLjIyN3YxLjYzN2gxLjYzNloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC0yKSIvPjwvc3ZnPg=="); 
}
