.o-header{
    background: $bg-white;
    position: relative;
    z-index: 4;
}

@media screen and (min-width: 992px){
    .o-header.-cart{
        .o-navProducts{
            display: none;
        }
    }
}

.o-headerNav{
    background: $bg-brand;
    height: 28px;
    padding: 0 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 400;

}
.o-headerNav__container{
    display: flex;
    flex-direction: row;
}
.o-headerNav__link{
    margin-right: 30px;
    color: rgba($text-white,0.7);
    font-size: 1.3rem;
    font-weight: 400;

    &:hover, &:active, &:focus{
        color: $text-white;
    }
}
.o-headerNav__contact{
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    
}
.o-headerNav__contactSingle{
    display: flex;
    margin-left: 30px;
    justify-content: center;
    align-items: center;
    color: $text-white;
}
.o-headerNav__contactIcon{
    height: 16px;
    width: 15px;
}
.o-headerNav__contactText{
    margin-left: 10px;
}

@media screen and (max-width: 991px){
    .o-headerNav{
        display: none;
    }
}